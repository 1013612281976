import React from 'react';
import { Helmet } from 'react-helmet';
import { PostCard } from "./post";
import Fonts from "./fonts";

const PostOgImage = (data) => {
  const background = data.pageContext.frontmatter.background;
  data.pageContext.frontmatter.background = "transparent";
  return <>
    <Helmet>
      <meta charSet="utf-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

      {Fonts}

      <style type="text/css">{`
        body {
          margin: 0;
          background: ${background};
          font-family: 'Open Sans', sans-serif;
        }
      `}</style>
    </Helmet>
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "100vh",
    }}>
      <div style={{
        maxWidth: data.pageContext.frontmatter.ogContentWidth || "600px"
      }}>
        <PostCard post={data.pageContext} noShadow smallTitle />
      </div>
    </div>
  </>;
};

export default PostOgImage;
